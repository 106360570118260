import React, { ReactElement, useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PlayingCard, { StoryPoint } from './PlayingCard';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            [theme.breakpoints.up("sm")]: {
                paddingLeft: theme.spacing(4),
            },
            marginBottom: theme.spacing(4),
        }
    })
);

export interface StoryPointCard {
    storyPoint: StoryPoint
    selected: boolean,
}

interface CardDeckProps {
    playingCards: StoryPointCard[];
    onCardSelected?: (storyPoint: StoryPoint) => void;
}

export default function CardDeck(props: CardDeckProps): ReactElement {
    const classes = useStyles();
    const [playingCards, setPlayingCards] = useState(props.playingCards);

    useEffect(() => {
        setPlayingCards(props.playingCards);
    }, [props.playingCards])

    function handleOnSelected(storyPoint: StoryPoint) {
        //unselect all other playingcards than the one selected (with the given storypoint)
        playingCards.forEach(card => card.selected = false);
        playingCards.filter(card => card.storyPoint === storyPoint).forEach(card => card.selected = true);
        setPlayingCards([...playingCards]);
        if (props.onCardSelected) {
            props.onCardSelected(storyPoint);
        }
    }

    function xsColumns(cardsCount: number) {
        // < 5 cards = 2 columns > 12/2 = 6 breakpoints
        // < 10 cards = 3 columns > 12/3 = 4 breakpoints
        // <=13(max) cards = 4 columns > 12/4 = 3 breakpoints
        return cardsCount < 5 ? 6 : cardsCount < 10 ? 4 : 3;
    }

    function mdColumns(cardsCount: number) {
        return cardsCount < 3 ? 6 : cardsCount < 7 ? 4 : cardsCount < 9 ? 3: 2;
    }

    return (
        <Grid container className={classes.container} spacing={2} direction="row" justifyContent="center" alignItems="flex-start">
           {playingCards.map(card => (
               <Grid key={card.storyPoint} item xs={xsColumns(playingCards.length)} md={mdColumns(playingCards.length)}>
                    <PlayingCard key={card.storyPoint} storyPoint={card.storyPoint} selected={card.selected} onSelected={handleOnSelected}></PlayingCard>
                </Grid>
            ))}
        </Grid>
    );
}