import React, { useState, useEffect, ReactElement } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

interface StyleProps {
    selected: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
    createStyles({
        aspectRatioWrapper: {
            width: '100%',
            paddingTop: "140%", //css hack to preserve aspect ratio
            position: "relative",
        },
        paper: (props: StyleProps) => ({
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: '0',
            bottom: '0',
            right: '0',
            left: '0',
            color: props.selected ? theme.palette.primary.contrastText : theme.palette.text.secondary,
            background: props.selected ? theme.palette.secondary.main : theme.palette.background.paper,

            transition: 'transform 0.2s', /* Animation */
            '&:hover': {
                transform: 'scale(1.05)',
            },
            '&:active': {
                background: theme.palette.background.paper,
            },
        }),
    }),
);

export enum StoryPoint {
    Zero = "0",
    Half = "1/2",
    One = "1",
    Two = "2",
    Three = "3",
    Five = "5",
    Eight = "8",
    Thirteen = "13",
    Twenty = "20",
    Fourty = "40",
    Hundred = "100",
    Question = "?",
    Coffee = "☕️"
}

interface CardProps {
    storyPoint: StoryPoint;
    selected: boolean;
    onSelected?: (storyPoint: StoryPoint) => void;
}


export default function PlayingCard(props: CardProps): ReactElement {
    const [elevation, setElevation] = useState(0);
    const [selected, setSelected] = useState(props.selected);
    const classes = useStyles({selected});

    /*
    useState hooks function argument is being used only once and not everytime the prop changes. 
    You must make use of useEffect hooks to implement what you would call the 
    componentWillReceiveProps/getDerivedStateFromProps functionality
    */
    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected])

    function handleMouseUp() {
        setSelected(true);
        if (props.onSelected) {
            props.onSelected(props.storyPoint);
        }
    }

    return (
        <div className={classes.aspectRatioWrapper}>
            /
            <Paper className={classes.paper} elevation={elevation} onMouseOver={() => setElevation(3)} onMouseLeave={() => setElevation(0)} onMouseUp={handleMouseUp}><Typography variant="h3">{props.storyPoint}</Typography></Paper>
        </div>
    );
}