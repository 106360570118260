export class Session {
    roomUrlId: string;
    userId: string;
    userName: string;

    constructor(roomUrlID: string, userId: string, userName: string) {
        this.roomUrlId = roomUrlID;
        this.userId = userId;
        this.userName = userName;
    }
}

export class SessionStorage {

    public SaveSession(roomUrlId: string, userId: string, userName: string): Session {
        
        const session = new Session(roomUrlId, userId, userName);
        window.localStorage.setItem(roomUrlId, JSON.stringify(session));
        //console.log('session saved for room with id: ' + roomUrlId);
        return session;
    }

    public GetSession(roomUrlId: string): Session | null {
         // Get from local storage by key
         const item = window.localStorage.getItem(roomUrlId);
         //console.log('session fetched for room with id: ' + roomUrlId);
         return item ? JSON.parse(item) as Session : null;
    }

    public DeleteSession(roomUrlId: string): void {
        window.localStorage.removeItem(roomUrlId);
    }
}