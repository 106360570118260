import React, { ReactElement } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { GameState } from './Room';

interface ModerationPanelProps {
    gameState: GameState;
    onStarted?: () => void;
    onStopped?: () => void;
}

export default function ModerationPanel(props: ModerationPanelProps): ReactElement {
 
    function handleButtonClick() {
        if ((props.gameState === GameState.WaitingForPLayers) || (props.gameState === GameState.Result)) {
            if (props.onStarted != null) {
                props.onStarted();
            }
        } else if (props.gameState === GameState.Playing) {
            if (props.onStopped) {
                props.onStopped();
            }
        }
    }

    function buttonTitle(gameState: GameState) {
        switch (gameState) {
            case GameState.WaitingForPLayers:
                return "Start Estimating"
            case GameState.Playing:
                return "Reveal Estimates"
            case GameState.Result:
                return "Start New Estimation Round"
            default:
                return "?"
        }
    }

    return (
        <Grid container spacing={3} direction="column" justifyContent="space-between" alignItems="center">
            <Grid item><Button onClick={handleButtonClick} variant="contained" size="large" color="secondary">{buttonTitle(props.gameState)}</Button></Grid>
        </Grid>
    );

}
