import { PlayingCardDTO } from "../api/DTO";

export class AppSettingsStorage {
    private DEFAULT_PLAYING_CARDS_KEY = "default_playing_cards";

    public SaveDefaultPlayingCards(playingCards: PlayingCardDTO[]): void {
        
        window.localStorage.setItem(this.DEFAULT_PLAYING_CARDS_KEY, JSON.stringify(playingCards));
        //console.log('session saved for room with id: ' + roomUrlId);
    }

    public GetDefaultPlayingCards(): PlayingCardDTO[] | null {
         // Get from local storage by key
         const item = window.localStorage.getItem(this.DEFAULT_PLAYING_CARDS_KEY);
         //console.log('session fetched for room with id: ' + roomUrlId);
         return item ? JSON.parse(item) as PlayingCardDTO[] : null;
    }
}