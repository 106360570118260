import React, { ReactElement } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Link, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.background.paper,
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            height: 40,
          }
    }),
);

export default function Footer(): ReactElement  {
    const classes = useStyles();

return (
    <div className={classes.footer}>
      <Typography variant="body2">Copyright © 2020 <Link target="_blank" href="https://agilebrains.dk">Agile Brains ApS</Link></Typography>
    </div>
  );
}