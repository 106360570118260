import React, { ReactElement } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography, Link, IconButton, Tooltip, Theme } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useAlert } from '../contexts/alert/useAlert';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'center',
    },
    container: {
      marginBottom: theme.spacing(3), 
      maxWidth: 800,
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
    },
    text: {
      maxWidth: 500,
    },
    icon: {
      color: theme.palette.icon.main,
    },
    link: {
      color: theme.palette.link.main,
    },
  }),
);

interface PlayerMessagingProps {
  isModerator: boolean
}

export default function PlayerMessaging(props: PlayerMessagingProps): ReactElement {
  const classes = useStyles();
  const roomUrl = window.location.href;
  const alert = useAlert();

  return (
    <div className={classes.root}>
      <Grid container className={classes.container} spacing={3} direction="column" justifyContent="space-between" alignItems="center" wrap="nowrap">
        <Grid item className={classes.text}>
          <Typography align="center" variant="h5">{props.isModerator ? "Invite players to the room" : "Wait for the moderator to start the session"}</Typography>
          <Typography align="center" variant="subtitle1">Send the link below to your team mates and soon they will start joining the room</Typography>
        </Grid>
        <Grid item>
          <Link className={classes.link} variant="body1" >{roomUrl}</Link>
          <CopyToClipboard text={roomUrl} onCopy={() => alert.showMessage("Room link is now copied to the clipboard 👍", "success")}>
            <Tooltip title="Copy Room Link" aria-label="Copy Room Link">
              <IconButton className={classes.icon} ><FileCopyIcon /></IconButton>
            </Tooltip>
          </CopyToClipboard>
        </Grid>
        {props.isModerator ?
          (<Grid item className={classes.text}><Typography variant="body2" align="center">You are the moderator and are in control of the estimation session. Click on your avatar icon at the top right corner to hand over moderation to other players. Press &quot;Start&quot; when all players has joined the room.</Typography></Grid>) :
          (<Grid item className={classes.text}><Typography variant="body2" align="center">When the moderator starts the game your planning poker card deck will appear and the estimation session can begin. In the meanwhile this is the perfect time to go grap a cup of coffee ☕️</Typography></Grid>)
        }
      </Grid>
    </div>
  );
}
