import React, { ReactElement } from 'react';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Tooltip, Typography } from '@material-ui/core';
import { StoryPoint } from './PlayingCard';
import { GameState } from './Room';
import { Timelapse, NotInterested, HourglassEmpty, WifiOff } from '@material-ui/icons';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import Lottie from "lottie-react";
import reconnectAnimLight from '../assets/lottie/reconnect-light.json';
import reconnectAnimDark from '../assets/lottie/reconnect-dark.json';
import { Skeleton } from '@material-ui/lab';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            marginTop: theme.spacing(0),
            background: theme.palette.background.paper,
            marginBottom: theme.spacing(3),
            borderRadius: 4,
            overflow: 'hidden',
        },
        header: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: theme.palette.primary.main,
            marginBottom: theme.spacing(1),
        },
        footer: {
        },
        h6: {
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
        avatar: {
            width: theme.spacing(3.2),
            height: theme.spacing(3.2),
        },
        itemAvatar: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        itemPlayer: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        itemStatus: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        moderatorIcon: {
            marginLeft: theme.spacing(1),
            color: theme.palette.icon.main,
        },
        icon: {
            color: theme.palette.icon.main,
        },
        textPrimary: {
            color: theme.palette.text.primary,
        },
        textDisabled: {
            color: theme.palette.text.disabled,
        }
    }),
);

export enum PlayerState {
    Active,
    Inactive,
    ConnectionProblem
}

export class PlayerViewModel {
    id: string;
    name: string;
    isModerator: boolean;
    storyPoint?: StoryPoint;
    state: PlayerState;

    constructor(id: string, name: string, isModerator: boolean, state: PlayerState, storyPoint?: StoryPoint) {
        this.id = id;
        this.name = name;
        this.isModerator = isModerator;
        this.state = state;
        this.storyPoint = storyPoint;
    }

    hasVoted(): boolean {
        return this.storyPoint != null;
    }

    currentAvatarSrc(): string {
        return avatarSrc(this.name, this.state);
    }
}

function avatarSrc(playerName: string, playerState: PlayerState): string {
    let url = "https://avatars.dicebear.com/api/bottts/:" + playerName + ".svg";
    if (playerState !== PlayerState.Active) {
        url += "?colorful=0&colors[]=grey&primaryColorLevel=500&secondaryColorLevel=500&mouthChance=0&textureChance=0";
    }
    return url;
}

interface PlayerListProps {
    gameState: GameState;
    players: PlayerViewModel[];
}

export default function PlayerList(props: PlayerListProps): ReactElement {
    const classes = useStyles();
    let rowKey = 0;
    const theme = useTheme();

    function renderStatus(gameState: GameState, player: PlayerViewModel) {
        if (player.state === PlayerState.ConnectionProblem) {
            return (<Tooltip title="Connection problem! Trying to reconnect..." aria-label="connection problem">
                <Lottie style={{width: 24}} animationData={theme.palette.type === 'light' ? reconnectAnimLight : reconnectAnimDark} />
            </Tooltip>);
        } else if (player.state === PlayerState.Inactive) {
            return (<Tooltip title="Inactive and has no connection to the room anymore" aria-label="inactive">
                <WifiOff color="disabled" />
            </Tooltip>);
        }
        switch (gameState) {
            case GameState.WaitingForPLayers:
                return (<Tooltip title="Waiting for the game to start" aria-label="waiting"><HourglassEmpty className={classes.icon} /></Tooltip>);
            case GameState.Playing:
                return player.hasVoted() ?
                    (<Tooltip title="Estimate given" aria-label="estimate given"><CheckCircleRoundedIcon className={classes.icon} /></Tooltip>)
                    : (<Tooltip title="Not decided on an estimate yet" aria-label="player deciding"><Timelapse className={classes.icon} /></Tooltip>);
            case GameState.Result:
                return player.storyPoint ? <Typography color="textPrimary" variant="body1">{player.storyPoint}</Typography> : <NotInterested className={classes.icon} />
            default:
                return null;
        }
    }

    return (
        <Grid container className={classes.container} spacing={1} direction="row">
            <Grid className={classes.header} item xs={12}><Typography className={classes.h6} variant="h6">Players</Typography></Grid>
            {props.gameState === GameState.Loading ? 
                <Grid item xs={12}> <Skeleton />
                    <Skeleton animation={false} />
                    <Skeleton animation="wave" /> 
                </Grid> :
                props.players.map(player => (
                    [
                        <Grid key={rowKey++} item xs={2} sm={3} lg={2} className={classes.itemAvatar}>
                            <img className={classes.avatar} src={player.currentAvatarSrc()} alt="" />
                            <link rel="preload" as="image" href={avatarSrc(player.name, PlayerState.Inactive)}></link>
                        </Grid>,
                        <Grid key={rowKey++} item xs={7} sm={6} lg={7} className={classes.itemPlayer}><Typography className={player.state === PlayerState.Active ? classes.textPrimary : classes.textDisabled} variant="body1">{player.name}</Typography> {player.isModerator && (<SportsEsportsIcon className={classes.moderatorIcon} />)}</Grid>,
                        <Grid key={rowKey++} item xs={3} className={classes.itemStatus}>{renderStatus(props.gameState, player)}</Grid>
                    ]
                ))
            }
            <Grid item xs={12} className={classes.footer}></Grid>
        </Grid>
    );
}