import ReactGA from 'react-ga';

export enum Category {
    Game = "Game",
    Room = "Room"
}

export enum Action {
    GameStarted = "Game Started",
    EstimationRoundStarted = "Estimation Round Started",
    EstimationRoundCompleted = "Estimation Round Completed",
    RoomCreated = "Room Created",
    RoomJoined = "Room Joined",
    PlayingCardsUpdated = "Playing Cards Updated",
    ModerationStarted = "Moderation Started",
    ModerationStopped = "Moderation Stopped",
}

export enum Timing {
    EstimationRoundLength = "Estimation Round Length",
}

export class Tracking {

    public initialize(trackingId: string): void {
        ReactGA.initialize(trackingId);
    }

    public event(category: Category, action: Action, label?: string, value?: number): void {
        ReactGA.event({
            category: category.toString(),
            action: action.toString(),
            label: label,
            value: value
        });
    }

    public timing(category: Category, timing: Timing, value: number, label?: string): void {
        ReactGA.timing({
            category: category.toString(),
            variable: timing.toString(),
            value: value, // in milliseconds
            label: label
        });
    }
}