import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import React, { ReactElement } from 'react';
import { AlertContext, AlertSeverity } from './AlertContext';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AlertProviderProps {}

export function AlertProvider(props: React.PropsWithChildren<AlertProviderProps>): ReactElement {
    const [isSnackBarShown, setIsSnackBarShown] = React.useState(false);
    const [severity, setSeverity] = React.useState<AlertSeverity>("success");
    const [message, setMessage] = React.useState("");

    const handleCloseSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsSnackBarShown(false);
        setMessage("");
    };

    function showAlert(message: string, severity: AlertSeverity) {
        setMessage(message);
        setSeverity(severity);
        setIsSnackBarShown(true);
    }

    return (
        <AlertContext.Provider value={{ showMessage: showAlert }}>
            {props.children}

            <Snackbar open={isSnackBarShown} autoHideDuration={severity === "error" ? 10000 : 5000} onClose={(handleCloseSnackbar)}>
                <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity={severity}>
                    {message}
                </MuiAlert>
            </Snackbar>
        </AlertContext.Provider>
    );
}