
export class UserStorage {
    private ORIGINAL_USER_ID_KEY = "original_user_id";
    private ORIGINAL_USER_CREATED_AT_KEY = "original_user_created_at"

    public SaveOriginalUserId (id: string): void {
        const currentId = this.GetOriginalUserId();
        //console.log("current original_user_id:" + currentId);
        if (currentId === null) {
            window.localStorage.setItem(this.ORIGINAL_USER_ID_KEY, JSON.stringify(id));
            //console.log('original_user_id saved: ' + id);
            this.SaveOriginalUserCreatedAt(new Date());
        } else {
            //console.log("original_user_id is not empty and cannot be overridden") 
        }
    }

    public GetOriginalUserId (): string | null {
        const item = window.localStorage.getItem(this.ORIGINAL_USER_ID_KEY);
        return item ? JSON.parse(item) as string : null;
    }

    private SaveOriginalUserCreatedAt (createAt: Date) {
        window.localStorage.setItem(this.ORIGINAL_USER_CREATED_AT_KEY, JSON.stringify(createAt));
        //console.log('original_user_created_at saved: ' + createAt);
    }

    public GetOriginalUserCreatedAt (): Date | null {
        const item = window.localStorage.getItem(this.ORIGINAL_USER_CREATED_AT_KEY);
        return item ? new Date(JSON.parse(item)) : null;
    }
}