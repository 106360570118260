import React, { useState, useContext, SyntheticEvent, ReactElement } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { useHistory } from "react-router-dom";
import { UserContext } from '../contexts/UserContext';
import { Typography } from '@material-ui/core';
import { CreateRoomRequest, HttpClient, RoomResponse } from '../api/HttpClient';
import { SessionStorage } from '../localstorage/SessionStorage';
import { useAlert } from '../contexts/alert/useAlert';
import { SubmitButton } from '../components/SubmitButton';
import { AppSettingsStorage } from '../localstorage/AppSettingsStorage';
import Footer from '../components/Footer';
import { UserStorage } from '../localstorage/UserStorage';
import { Tracking, Category, Action } from '../util/Tracking';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: theme.spacing(3)
        },
    }),
);

export default function CreateRoom(): ReactElement {
    const [userName, setUserName] = useState('');
    const { setUser } = useContext(UserContext);
    const classes = useStyles();
    const history = useHistory();
    const _sessionsStorage = new SessionStorage();
    const _userStorage = new UserStorage();
    const alert = useAlert();
    const [isLoading, setIsLoading] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const track = new Tracking();

    async function handleSubmit(event: SyntheticEvent) {
        event.preventDefault();

        if (userName.length === 0) {
            setIsValid(false);
            return
        } else {
            setIsValid(true)
        }

        setIsLoading(true);
        const httpClient = new HttpClient();
        try {
            const request = new CreateRoomRequest(userName);

            const appSettings = new AppSettingsStorage();
            const defaultPlayingCards = appSettings.GetDefaultPlayingCards();
            if (defaultPlayingCards) {
                console.log('creating room with custom cards: ' + defaultPlayingCards);
                request.playingCards = defaultPlayingCards;
            }

            const response = await httpClient.post('/rooms', request) as RoomResponse;

            const player = response.players.find(p => p.name === userName);
            if (player) {
                track.event(Category.Room, Action.RoomCreated);

                _sessionsStorage.SaveSession(response.urlId, player.id, userName);
                setUser(player.id, player.name, true);
                _userStorage.SaveOriginalUserId(player.id);
                history.push("/" + response.urlId);

            } else {
                setIsLoading(false);
                alert.showMessage("an unexpected error occured, please try again 🤞", "error");
            }

        } catch (e) {
            setIsLoading(false);
            alert.showMessage("Not able to create room, please try again 🤞", "error");
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <Grid container className={classes.container} spacing={3} direction="column" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography align="center" variant="h5">Create a planning poker room</Typography>
                        <Typography align="center" variant="subtitle1">and start estimating with your team right away</Typography>
                    </Grid>
                    <Grid item><TextField error={!isValid} disabled={isLoading} onChange={event => setUserName(event.target.value)} id="outlined-basic" label="Please enter your name" variant="outlined" color="secondary" /></Grid>
                    <Grid item><SubmitButton isLoading={isLoading}>Create Room</SubmitButton></Grid>
                </Grid>
            </form>
            <Footer />
        </div>
    );
}
