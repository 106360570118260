import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { Button, Checkbox, createStyles, DialogActions, DialogContent, FormControl, FormControlLabel, FormGroup, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { StoryPoint } from './PlayingCard';
import { useParams } from 'react-router-dom';
import { RoomParams } from '../types/RouterTypes';
import { HttpClient, RoomResponse, UpdatePlayingCardsRequest } from '../api/HttpClient';
import { useAlert } from '../contexts/alert/useAlert';
import { DialogTitle } from '../components/DialogTitle';
import { DialogTransition } from '../components/DialogTransition';
import { PlayingCardDTO } from '../api/DTO';
import { AppSettingsStorage } from '../localstorage/AppSettingsStorage';
import { Tracking, Category, Action } from '../util/Tracking';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(3),
        }
    }),
);

export interface SettingsDialogProps {
    open: boolean;
    onClose: () => void;
}

export function SettingsDialog(props: SettingsDialogProps): ReactElement {
    const { id: roomUrlId } = useParams<RoomParams>();
    const alert = useAlert();
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const track = new Tracking();

    const [open, setOpen] = React.useState(props.open);
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = React.useState({
        zero: false,
        half: false,
        one: false,
        two: false,
        three: false,
        five: false,
        eight: false,
        thirteen: false,
        twenty: false,
        fourty: false,
        hundred: false,
        question: false,
        coffee: false,
    });

    const { zero, half, one, two, three, five, eight, thirteen, twenty, fourty, hundred, question, coffee } = state;

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleTransitionEnd = () => {
        if (!open) {
            props.onClose();
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const httpClient = new HttpClient();
                const response = await httpClient.get('/rooms/' + roomUrlId) as RoomResponse;
                const cards = response.playingCards;

                setState({
                    zero: cards.find(c => c.value === StoryPoint.Zero) ? true : false,
                    half: cards.find(c => c.value === StoryPoint.Half) ? true : false,
                    one: cards.find(c => c.value === StoryPoint.One) ? true : false,
                    two: cards.find(c => c.value === StoryPoint.Two) ? true : false,
                    three: cards.find(c => c.value === StoryPoint.Three) ? true : false,
                    five: cards.find(c => c.value === StoryPoint.Five) ? true : false,
                    eight: cards.find(c => c.value === StoryPoint.Eight) ? true : false,
                    thirteen: cards.find(c => c.value === StoryPoint.Thirteen) ? true : false,
                    twenty: cards.find(c => c.value === StoryPoint.Twenty) ? true : false,
                    fourty: cards.find(c => c.value === StoryPoint.Fourty) ? true : false,
                    hundred: cards.find(c => c.value === StoryPoint.Hundred) ? true : false,
                    question: cards.find(c => c.value === StoryPoint.Question) ? true : false,
                    coffee: cards.find(c => c.value === StoryPoint.Coffee) ? true : false,
                });
                setIsLoading(false);
                
            } catch (e) {
                setIsLoading(false);
                alert.showMessage("Not able to initialize settings, please try again 🤞", "error");
            }
        };
        fetchData();
    }, []); 

    async function handleSave(event: SyntheticEvent) {
        event.preventDefault();

        setIsLoading(true);
        const httpClient = new HttpClient();
        try {

            const request = new UpdatePlayingCardsRequest(roomUrlId);
            let order = 0;
            if (zero) { request.playingCards.push(new PlayingCardDTO(order++, StoryPoint.Zero)); }
            if (half) { request.playingCards.push(new PlayingCardDTO(order++, StoryPoint.Half)); }
            if (one) { request.playingCards.push(new PlayingCardDTO(order++, StoryPoint.One)); }
            if (two) { request.playingCards.push(new PlayingCardDTO(order++, StoryPoint.Two)); }
            if (three) { request.playingCards.push(new PlayingCardDTO(order++, StoryPoint.Three)); }
            if (five) { request.playingCards.push(new PlayingCardDTO(order++, StoryPoint.Five)); }
            if (eight) { request.playingCards.push(new PlayingCardDTO(order++, StoryPoint.Eight)); }
            if (thirteen) { request.playingCards.push(new PlayingCardDTO(order++, StoryPoint.Thirteen)); }
            if (twenty) { request.playingCards.push(new PlayingCardDTO(order++, StoryPoint.Twenty)); }
            if (fourty) { request.playingCards.push(new PlayingCardDTO(order++, StoryPoint.Fourty)); }
            if (hundred) { request.playingCards.push(new PlayingCardDTO(order++, StoryPoint.Hundred)); }
            if (question) { request.playingCards.push(new PlayingCardDTO(order++, StoryPoint.Question)); }
            if (coffee) { request.playingCards.push(new PlayingCardDTO(order++, StoryPoint.Coffee)); }

            await httpClient.post('/rooms/updatePlayingCards', request);
            const appSettings = new AppSettingsStorage();
            appSettings.SaveDefaultPlayingCards(request.playingCards);

            const cardDeck = request.playingCards.map<string>(card => card.value);
            track.event(Category.Room, Action.PlayingCardsUpdated, cardDeck.toString());

            setIsLoading(false);

        } catch (e) {
            setIsLoading(false);
            alert.showMessage("Not able to update cards, please try again 🤞", "error");
        }
       handleClose();
    }

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullScreen={fullScreen} TransitionComponent={DialogTransition} keepMounted onTransitionEnd={handleTransitionEnd}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Settings
              </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
                    <Grid item xs={4}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={zero} onChange={handleCheckboxChange} name="zero" />} label={<Typography color="textPrimary" variant="body1">{StoryPoint.Zero.toString()}</Typography>} />
                                <FormControlLabel control={<Checkbox checked={half} onChange={handleCheckboxChange} name="half" />} label={<Typography color="textPrimary" variant="body1">{StoryPoint.Half.toString()}</Typography>} />
                                <FormControlLabel control={<Checkbox checked={one} onChange={handleCheckboxChange} name="one" />} label={<Typography color="textPrimary" variant="body1">{StoryPoint.One.toString()}</Typography>} />
                                <FormControlLabel control={<Checkbox checked={two} onChange={handleCheckboxChange} name="two" />} label={<Typography color="textPrimary" variant="body1">{StoryPoint.Two.toString()}</Typography>} />
                            </FormGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={three} onChange={handleCheckboxChange} name="three" />} label={<Typography color="textPrimary" variant="body1">{StoryPoint.Three.toString()}</Typography>} />
                                <FormControlLabel control={<Checkbox checked={five} onChange={handleCheckboxChange} name="five" />} label={<Typography color="textPrimary" variant="body1">{StoryPoint.Five.toString()}</Typography>} />
                                <FormControlLabel control={<Checkbox checked={eight} onChange={handleCheckboxChange} name="eight" />} label={<Typography color="textPrimary" variant="body1">{StoryPoint.Eight.toString()}</Typography>} />
                                <FormControlLabel control={<Checkbox checked={thirteen} onChange={handleCheckboxChange} name="thirteen" />} label={<Typography color="textPrimary" variant="body1">{StoryPoint.Thirteen.toString()}</Typography>} />
                            </FormGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={twenty} onChange={handleCheckboxChange} name="twenty" />} label={<Typography color="textPrimary" variant="body1">{StoryPoint.Twenty.toString()}</Typography>} />
                                <FormControlLabel control={<Checkbox checked={fourty} onChange={handleCheckboxChange} name="fourty" />} label={<Typography color="textPrimary" variant="body1">{StoryPoint.Fourty.toString()}</Typography>} />
                                <FormControlLabel control={<Checkbox checked={hundred} onChange={handleCheckboxChange} name="hundred" />} label={<Typography color="textPrimary" variant="body1">{StoryPoint.Hundred.toString()}</Typography>} />
                                <FormControlLabel control={<Checkbox checked={question} onChange={handleCheckboxChange} name="question" />} label={<Typography color="textPrimary" variant="body1">{StoryPoint.Question.toString()}</Typography>} />
                                <FormControlLabel control={<Checkbox checked={coffee} onChange={handleCheckboxChange} name="coffee" />} label={<Typography color="textPrimary" variant="body1">{StoryPoint.Coffee.toString()}</Typography>} />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={handleSave}>Save Settings</Button>
            </DialogActions>
        </Dialog>
    );
}