/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

export type AlertSeverity = 'success' | 'info' | 'warning' | 'error';

export interface AlertContextProps {
   /**
   * Show MuiAlert Snackbar message
   * @param {string} message message to display
   * @param {AlertSeverity} severity label for the action button
   */
  showMessage: (message: string, severity: AlertSeverity) => void;
}

export const AlertContext = React.createContext<AlertContextProps>({
    showMessage: (message: string, severity: AlertSeverity) => {}
});