/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

export class ContextUser {
  id: string;
  name: string;
  isModerator: boolean; 

  constructor(id: string, name: string, isModerator: boolean) {
    this.id = id;
    this.name = name;
    this.isModerator = isModerator;
  }
}

export interface UserContextProps {
  user?: ContextUser;
  setUser: (id: string, name: string, isModerator: boolean) => void;
}

export const UserContext = React.createContext<UserContextProps>({
    user: undefined,
    setUser: (id: string, name: string, isModerator: boolean) => {},
});