import { PlayingCardDTO } from "./DTO"

export class ConflictError extends Error {
  constructor(message: string) {
    super(message);
  }
}

export class NotFoundError extends Error {
  constructor(message: string) {
    super(message);
  }
}

enum HttpMethod {
  Get = "get",
  Post = "post",
  Put = "put"
}

export class HttpClient {

  private async http<T>(method: HttpMethod, path: string, body?: RequestBody): Promise<T> {
    const init: RequestInit = {
      method: method.toString(),
      headers:
      {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }

    if (body) {
      init.body = JSON.stringify(body);
    }

    const baseUrl = process.env.PUBLIC_URL + "/api";
    //const baseUrl =  process.env.REACT_APP_API_URL;
    const request = new Request(baseUrl + path, init)
    const response = await fetch(request)
    if (!response.ok) {
      //TODO pri2: improve error handling with promise where you can extract error message from server
      if (response.status === 409) {
        throw new ConflictError(response.statusText)
      } else if (response.status === 404) {
        throw new NotFoundError(response.statusText);
      } else {
        throw new Error(response.statusText);
      }
    }
    // may cause error if there is no body, return empty array
    return response.json().catch(() => ({}))
  }

  async get<T>(path: string): Promise<T> {
    return await this.http<T>(HttpMethod.Get, path)
  }

  async post<T>(path: string, body: RequestBody): Promise<T> {
    return await this.http<T>(HttpMethod.Post, path, body)
  }

  async put<T>(path: string, body: RequestBody): Promise<T> {
    return await this.http<T>(HttpMethod.Put, path, body)
  }

}

export class RequestBody {}

export class UpdatePlayingCardsRequest implements RequestBody {
  roomUrlId: string;
  playingCards: PlayingCardDTO[] = [];

  constructor(roomUrlId: string) {
    this.roomUrlId = roomUrlId;
  }
}

export class CreateRoomRequest  implements RequestBody {
  playerName: string;
  playingCards?: PlayingCardDTO[] = [];

 constructor(playerName: string) {
    this.playerName = playerName;
  }
}

export interface RoomResponse {
  id: string,
  urlId: string,
  state: string
  players: [{
    id: string,
    name: string,
    isModerator: true,
    storyPoint?: string,
    lastSeenAt?: Date,
    connections: [{
      connectionId: number,
      createdAt: Date,
    }]
  }]
  playingCards: [{
    order: number,
    value: string,
  }]
}