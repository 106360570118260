import React, { ReactElement } from 'react';
import { createStyles, Grid, IconButton, makeStyles, Menu, MenuItem, Theme, Typography } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { ContextUser } from '../contexts/UserContext';
import { Tracking, Category, Action } from '../util/Tracking';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userName: {
      color: theme.palette.primary.contrastText,
    }
  }),
);

interface UserMenuProps {
    user?: ContextUser;
    onModerationStartClicked: () => void;
    onModerationStopClicked: () => void;
    onSettingsClicked: () => void;
}

export function UserMenu(props: UserMenuProps): ReactElement {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const track = new Tracking();
    const classes = useStyles();

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleStartClicked = () => {
        handleCloseMenu();
        props.onModerationStartClicked();
        track.event(Category.Game, Action.ModerationStarted);
    }

    const handleStopClicked = () => {
        handleCloseMenu();
        props.onModerationStopClicked();
        track.event(Category.Game, Action.ModerationStopped);
    }

    const handleSettingsClicked = () => {
        handleCloseMenu();
        props.onSettingsClicked();

    }

    return (
        <div>
            {props.user && (
                <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                    <Grid item><Typography className={classes.userName} variant="body1"> {props.user.name}</Typography></Grid>
                    <Grid item>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                    </Grid>

                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleCloseMenu}
                    >
                        {!props.user.isModerator && (<MenuItem onClick={handleStartClicked}><Typography color="textPrimary" variant="body1">Start Moderating</Typography></MenuItem>)}
                        {props.user.isModerator && (<MenuItem onClick={handleStopClicked}><Typography color="textPrimary">Stop Moderating</Typography></MenuItem>)}
                        {props.user.isModerator && (<MenuItem onClick={handleSettingsClicked}><Typography color="textPrimary">Settings</Typography></MenuItem>)}
                    </Menu>
                </Grid>
            )}
        </div>
    );
}