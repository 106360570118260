import { Button, CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { ReactElement } from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            marginTop: theme.spacing(1),
        },
        circularProgress: {
            color: theme.palette.primary.contrastText,
        },
    }),
);

interface SubmitButtonProps {
    isLoading: boolean
}

export function SubmitButton(props: React.PropsWithChildren<SubmitButtonProps>): ReactElement {
    const classes = useStyles();
    
    return (
        <Button className={classes.button} type="submit" variant="contained" size="large" color="secondary">
            {props.isLoading ? <CircularProgress className={classes.circularProgress} size={20} /> : props.children}
        </Button>
    );
}
