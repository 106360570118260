import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { ThemeProvider, createStyles, makeStyles, Theme, responsiveFontSizes } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CreateRoom from '../features/CreateRoom';
import Room from '../features/Room';
import { BrowserRouter as Router, Switch, Route, useParams } from 'react-router-dom';
import { ContextUser, UserContext } from '../contexts/UserContext';
import JoinRoom from '../features/JoinRoom';
import { SessionStorage } from '../localstorage/SessionStorage';
import { lightTheme } from '../themes/lightTheme';
import { darkTheme } from '../themes/darkTheme';
import { RoomParams } from '../types/RouterTypes';
import { UserMenu } from './UserMenu';
import { AlertProvider } from '../contexts/alert/AlertProvider';
import { SettingsDialog } from '../features/SettingsDialog';
import About from '../main/About';
import AboutButton from '../components/AboutButton';
import { Tracking } from '../util/Tracking';
import { CssBaseline, useMediaQuery } from '@material-ui/core';
import Elmahio from 'elmah.io.javascript';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }),
);

//useParams only works for child components. this is why RoomContentWrapper is extracted from App
function RoomContentWrapper() {
  const { id: roomUrlId } = useParams<RoomParams>();
  const { user, setUser } = useContext(UserContext);
  const session = new SessionStorage().GetSession(roomUrlId);

  useEffect(() => {
    if (!user) {
      //console.log('update usercontext from local storage session after hard refresh')
      if (session) {
        //console.log('setUser from local storage(' + session.userId + ',' + session.userName + ');');
        setUser(session.userId, session.userName, false);
      }
    }
  }, [])

  return (
    session ? (<Room roomUrlId={roomUrlId} />) : (<JoinRoom roomUrlId={roomUrlId} />)
  );
}

export default function App(): ReactElement {
  const classes = useStyles();
  const [user, setUser] = useState<ContextUser>();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const track = new Tracking();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  
  function setContextUser(id: string, name: string, isModerator: boolean) {
    setUser(new ContextUser(id, name, isModerator));
  }

  function setContextUserIsModerator(isModerator: boolean) {
    if (user) {
      setUser(new ContextUser(user.id, user.name, isModerator));
    }
  }

  useEffect(() => {
    const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_UNIVERSAL_TRACKING_ID;
    if (trackingId) {
      track.initialize(trackingId);
    }

    const apiKey = process.env.REACT_APP_ELMAHIO_API_KEY;
    const logId = process.env.REACT_APP_ELMAHIO_LOG_ID;
    if (apiKey && logId) {
      new Elmahio({apiKey: apiKey, logId: logId, breadcrumbs: true});
    }
  }, [])

  return (
    <ThemeProvider theme={responsiveFontSizes(prefersDarkMode ? darkTheme : lightTheme)}>
      <CssBaseline />
      <UserContext.Provider value={{ user: user, setUser: setContextUser }}>
        <AlertProvider>
          <Router>
            <div className={classes.root}>
              <AppBar position="sticky">
                <Toolbar>
                  <Typography variant="h6" className={classes.title}>Story Point Poker</Typography>
                  {!user && <AboutButton />}
                  <UserMenu user={user} onModerationStartClicked={() => setContextUserIsModerator(true)} onModerationStopClicked={() => setContextUserIsModerator(false)} onSettingsClicked={() => setIsSettingsOpen(true)} />
                </Toolbar>
              </AppBar>

              <Switch>
                <Route path="/about">
                  <About />
                </Route>
                <Route path="/:id">
                  <RoomContentWrapper />
                  {isSettingsOpen && <SettingsDialog open={isSettingsOpen} onClose={() => setIsSettingsOpen(false)} />}
                </Route>
                <Route path="/">
                  <CreateRoom />
                </Route>
              </Switch>

            </div>
          </Router>
        </AlertProvider>
      </UserContext.Provider>
    </ThemeProvider>
  );
}