import React, { useState, useContext, useEffect, useRef, ReactElement } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PlayerList, { PlayerState, PlayerViewModel } from './PlayerList';
import CardDeck, { StoryPointCard } from './CardDeck';
import PlayerMessaging from './PlayerMessaging';
import ModerationPanel from './ModerationPanel';
import { ContextUser, UserContext } from '../contexts/UserContext';
import EstimationResult, { PlayerEstimate } from './EstimationResult';
import { StoryPoint } from './PlayingCard';
import { HttpClient, RoomResponse } from '../api/HttpClient';
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { useAlert } from '../contexts/alert/useAlert';
import { SessionStorage } from '../localstorage/SessionStorage';
import { CardSelectedMessage, GameStateChangedMessage, Message, PlayingCardsUpdatedMessage } from '../api/SignalR';
import { GameStorage } from '../localstorage/GameStorage';
import { Tracking, Category, Action, Timing } from '../util/Tracking';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'center',
        },
        outerContainer: {
            padding: theme.spacing(4),
            maxWidth: (props: { playingCardsCount: number }) => props.playingCardsCount < 7 ? 900 : props.playingCardsCount < 9 ? 1100 : 1400,
        },
        loaderContainer: {
            minHeight: 200,
        },
    }),
);

export enum GameState {
    NotStarted = "not_started",
    WaitingForPLayers = "waiting_for_players",
    Playing = "playing",
    Result = "result",
    Loading = "loading"
}

export interface RoomProps {
    roomUrlId: string
}

let _roundCount = 0;

export default function Room(props: RoomProps): ReactElement {
    const alert = useAlert();
    const gameStorage = new GameStorage();
    const track = new Tracking();

    const [gameState, setGameState] = useState<GameState>(GameState.Loading);
    const { user, setUser } = useContext(UserContext);
    const connection = useRef<HubConnection>(new HubConnectionBuilder().withAutomaticReconnect().configureLogging(LogLevel.Error).withUrl("/roomhub").build());
    const [players, setPlayers] = useState<PlayerViewModel[]>([]);
    const playersRef = useRef(players);
    const [playingCards, setPlayingCards] = useState<StoryPointCard[]>([]);
    const playingCardsRef = useRef(playingCards);
    const classes = useStyles({ playingCardsCount: gameStorage.GetNumberOfPlayingCards() });

    async function loadDataAndUpdateState(user: ContextUser) {
        try {
            //TODO pri2: is it possible to avoid fetching the board once again after create/join? how to pass probs through the router
            const httpClient = new HttpClient();
            const response = await httpClient.get('/rooms/' + props.roomUrlId) as RoomResponse;

            playersRef.current = response.players.map(p => new PlayerViewModel(p.id, p.name, p.isModerator, p.id === user.id ? PlayerState.Active : p.connections.length > 0 ? PlayerState.Active : PlayerState.Inactive, p.storyPoint as StoryPoint));

            //set local user state after browser refresh
            const player = playersRef.current.find(p => p.id === user.id);
            if (player) {
                setUser(player.id, player.name, player.isModerator);

            } else {
                alert.showMessage("You are no longer a part of the room, please refresh your browser to rejoin 🤞", "error");
                const sessionStorage = new SessionStorage();
                sessionStorage.DeleteSession(props.roomUrlId);
            }

            setPlayers([...playersRef.current]);
            const orderedCards = response.playingCards.sort(c => c.order);
            playingCardsRef.current = orderedCards.map(p => ({ storyPoint: p.value as StoryPoint, selected: player?.storyPoint === p.value }))
            setPlayingCards([...playingCardsRef.current]);
            gameStorage.SaveNumberOfPlayingCards(playingCardsRef.current.length);
            setGameState(response.state as GameState);

        } catch (e) {
            alert.showMessage("Not able to load room, please try again 🤞", "error");
        }
    }


    //TODO pri2: connection handling should be extracted to something independant from UI renders
    useEffect(() => {
        if (user) {
            initializeWebSocketMessageHandlers(user);
            connectWebSocketAndJoinRoom(user);
            loadDataAndUpdateState(user);

            window.addEventListener("focus", () => {
                //alert.showMessage("window got focus with connection state '" + connection.current.state + "'", "info");
                if (connection.current.state === HubConnectionState.Disconnected) {
                    changeStateForPlayer(user.id, PlayerState.ConnectionProblem);
                    connectWebSocketAndJoinRoom(user);
                    loadDataAndUpdateState(user);
                }
            });
        }
    }, [user?.id]);

    function connectWebSocketAndJoinRoom(user: ContextUser): void {
        connection.current.start().then(() => {
            //console.log("websocket connected with connection id: " + connection.current.connectionId?.toString());
            sendJoinRoomMessage(user);

        }).catch(err => document.write(err));
    }

    function ifMessageIsFromAnotherConnectionThen(connectionId: string, action: () => void) {
        if (connection.current.connectionId?.toString() !== connectionId) {
            action();
        }
    }

    function sendJoinRoomMessage(user: ContextUser): void {
        //console.log("websocket join room with username:'" + user.name + "'")
        connection.current.send("JoinRoom", user.id, user.name, props.roomUrlId).then(() => {
            //console.log("Messaage 'JoinRoom' sent")
        });
    }

    function initializeWebSocketMessageHandlers(user: ContextUser) {
        connection.current.on("gameStateChanged", (message: GameStateChangedMessage) => {
            ifMessageIsFromAnotherConnectionThen(message.sender.connectionId, () => {
                changeGameState(message.body.state as GameState, false);
            });
        });

        connection.current.on("playerJoinedRoom", (message: Message) => {
            ifMessageIsFromAnotherConnectionThen(message.sender.connectionId, () => {
                const newPlayer = new PlayerViewModel(message.sender.playerId, message.sender.playerName, false, PlayerState.Active, undefined);
                const existingPlayer = playersRef.current.find(p => p.id === newPlayer.id);
                if (existingPlayer) {
                    existingPlayer.state = PlayerState.Active;
                } else {
                    playersRef.current.push(newPlayer);
                }
                setPlayers([...playersRef.current]);
            });
        });

        connection.current.on("playerCardSelected", (message: CardSelectedMessage) => {
            ifMessageIsFromAnotherConnectionThen(message.sender.connectionId, () => {
                handleCardSelectedByPlayer(message.sender.playerId, message.body.storyPoint as StoryPoint);
            });
        });

        connection.current.on("playerModerationStarted", (message: Message) => {
            ifMessageIsFromAnotherConnectionThen(message.sender.connectionId, () => {
                changeModerationForPlayer(message.sender.playerId, true);
            });
        });

        connection.current.on("playerModerationStopped", (message: Message) => {
            ifMessageIsFromAnotherConnectionThen(message.sender.connectionId, () => {
                changeModerationForPlayer(message.sender.playerId, false);
            });
        });

        connection.current.on("playerDisconnected", (message: Message) => {
            ifMessageIsFromAnotherConnectionThen(message.sender.connectionId, () => {
                const player = playersRef.current.find(p => p.id === message.sender.playerId);
                if (player) {
                    player.state = PlayerState.Inactive;
                    setPlayers([...playersRef.current]);
                }
            });
        });

        connection.current.on("playingCardsUpdated", (message: PlayingCardsUpdatedMessage) => {
            const orderedCards = message.playingCards.sort(c => c.order);
            playingCardsRef.current = orderedCards.map(p => ({ storyPoint: p.value as StoryPoint, selected: false }))
            gameStorage.SaveNumberOfPlayingCards(playingCardsRef.current.length);
            resetGame();
        });

        connection.current.onclose((error?: Error | undefined) => {
            //first called when reconnect fails
            console.log("websocket closed with error: " + error?.message);
            changeStateForPlayer(user.id, PlayerState.Inactive);
            alert.showMessage("Connection lost! Please try to refresh your browser 🤞", "error");
        });

        connection.current.onreconnecting((error?: Error | undefined) => {
            //called as the first thing when the connection is lost
            console.log("websocket reconnecting with error: " + error?.message);
            changeStateForPlayer(user.id, PlayerState.ConnectionProblem);
        });

        connection.current.onreconnected((connectionId?: string) => {
            //console.log("websocket reconnected with connection id: " + connectionId?.toString());
            //covers the scenario (among others) where a mobile has been locked and unlocks to participate again.
            //when IsConnected is set to true this triggers "JoinRoom" message which notifies other players that I'm back online
            //loadDataAndUpdateState() updates my own game state since there could have been messages that I haven't recieved while being offline
            sendJoinRoomMessage(user);
            changeStateForPlayer(user.id, PlayerState.Active);
            loadDataAndUpdateState(user);
        })
    }

    useEffect(() => {
        if (user) {
            changeModerationForPlayer(user.id, user.isModerator);
            if (connection.current.state === HubConnectionState.Connected) {
                const message = user.isModerator ? "StartModerating" : "StopModerating";
                connection.current.send(message, user.id, user.name, props.roomUrlId).then(() => {
                    //console.log("Messaage '" + message + "' sent")
                });
            }
        }
    }, [user?.isModerator]);

    function changeGameState(state: GameState, notifyOtherPlayers: boolean) {
        if (state === GameState.Playing) {
            resetGame();
            _roundCount++;
        }
        setGameState(state)
        if (notifyOtherPlayers) {
            if (user) {
                if (connection.current.state === HubConnectionState.Connected) {
                    connection.current.send("ChangeGameState", user.id, user.name, props.roomUrlId, state).then(() => {
                        //console.log("Messaage 'ChangeGameState' sent")
                    });
                }
            }
        }
    }

    function resetGame() {
        playersRef.current.forEach(p => p.storyPoint = undefined);
        setPlayers([...playersRef.current]);
        playingCardsRef.current.forEach(c => c.selected = false);
        setPlayingCards([...playingCardsRef.current]);
    }

    function handleOnGameStarted() {
        if (gameState === GameState.WaitingForPLayers) {
            track.event(Category.Game, Action.GameStarted, playersRef.current.length + " players", playersRef.current.length);
        }

        changeGameState(GameState.Playing, true);

        track.event(Category.Game, Action.EstimationRoundStarted, 'estimation round ' + _roundCount, _roundCount);
        gameStorage.SaveLastEstimationRoundStartedAt(new Date());
    }

    function handleOnGameStopped() {
        changeGameState(GameState.Result, true);

        track.event(Category.Game, Action.EstimationRoundCompleted, 'estimation round ' + _roundCount, _roundCount);

        const lastRoundStartedAt = gameStorage.GetLastEstimationRoundStartedAt();
        if (lastRoundStartedAt !== null) {
            const timestamp = new Date();
            const roundLengthInMS = timestamp.getTime() - lastRoundStartedAt.getTime();
            track.timing(Category.Game, Timing.EstimationRoundLength, roundLengthInMS, 'estimation round ' + _roundCount);
        }
    }

    function handleCardSelected(storyPoint: StoryPoint) {
        if (user) {
            handleCardSelectedByPlayer(user.id, storyPoint);
            if (connection.current.state === HubConnectionState.Connected) {
                connection.current.send("SelectCard", user.id, user.name, props.roomUrlId, storyPoint).then(() => {
                    //console.log("Messaage 'SelectCard' sent")
                });
            }
        }
    }

    function handleCardSelectedByPlayer(playerId: string, storyPoint: StoryPoint) {
        const p = playersRef.current.find(p => p.id === playerId);
        if (p) {
            p.storyPoint = storyPoint;
            setPlayers([...playersRef.current]);
            // update the playingcard in case the player has multiple sessions open at the same time
            if (p.id === user?.id) {
                const selectedCard = playingCardsRef.current.find(c => c.storyPoint === storyPoint);
                if (selectedCard) {
                    playingCardsRef.current.forEach(c => c.selected = false);
                    selectedCard.selected = true;
                    setPlayingCards([...playingCardsRef.current]);
                }
            }
        }
    }

    function changeModerationForPlayer(playerId: string, isModerator: boolean) {
        const p = playersRef.current.find(p => p.id === playerId);
        if (p) {
            p.isModerator = isModerator;
            setPlayers([...playersRef.current]);
        }
    }

    function changeStateForPlayer(playerId: string, playerState: PlayerState) {
        const p = playersRef.current.find(p => p.id === playerId);
        if (p) {
            p.state = playerState;
            setPlayers([...playersRef.current]);
        }
    }

    function renderContent(gameState: GameState, isModerator: boolean) {
        switch (gameState) {
            case GameState.WaitingForPLayers:
                return <PlayerMessaging isModerator={isModerator} />
            case GameState.Playing:
                return <CardDeck playingCards={playingCards} onCardSelected={handleCardSelected} />
            case GameState.Result:
                return <EstimationResult playerEstimates={players.map<PlayerEstimate>(p => ({ playerName: p.name, storyPoint: p.storyPoint }))} roundCount={_roundCount} />
            case GameState.Loading:
                return <Grid container className={classes.loaderContainer} spacing={2} justifyContent="center" alignItems="center" direction="column">
                    <Grid item xs={12}><CircularProgress color="secondary" /></Grid>
                </Grid>
            default:
                return null;
        }
    }

    return (
        <div className={classes.root}>
            <Grid container className={classes.outerContainer} spacing={0} direction="row" justifyContent="flex-start" alignItems="flex-start">
                <Grid item xs={12} sm={3}>
                    <PlayerList gameState={gameState} players={players} />
                </Grid>

                <Grid item xs={12} sm={9}>
                    {user && renderContent(gameState, user.isModerator)}

                    {user && gameState !== GameState.Loading && user.isModerator ? (<ModerationPanel gameState={gameState} onStarted={handleOnGameStarted} onStopped={handleOnGameStopped} />) : (<div />)}
                </Grid>
            </Grid>
        </div>
    );
}