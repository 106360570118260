import React, { ReactElement } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Theme, useTheme } from '@material-ui/core';
import { PieChart } from 'react-minimal-pie-chart';
import { StoryPoint } from './PlayingCard';
import Wootric from '../components/Wootric';
import { useUser }  from '../contexts/useUser';
import { UserStorage } from '../localstorage/UserStorage';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginBottom: theme.spacing(2),
        },
        pieChart: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        }
    }),
);

export interface PlayerEstimate {
    playerName: string;
    storyPoint?: StoryPoint;
}

export interface EstimationResultProp {
    playerEstimates: PlayerEstimate[];
    roundCount: number;
}

function groupBy<T, K>(list: T[], getKey: (item: T) => K) {
    const map = new Map<K, T[]>();
    list.forEach((item) => {
        const key = getKey(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return Array.from(map.values());
}

/*
* @param playerEstimates playerestimates from properties$
* filters out empty estimates
* group the esimates by players giving the same estimate
* sorts by number of plauers giving  the same estimate so that they are shown first
*/
function calcPieData(playerEstimates: PlayerEstimate[]) {
    const esimates = playerEstimates.filter(pe => pe.storyPoint);
    const pieData = groupBy(esimates, (pe) => pe.storyPoint);
    pieData.sort((a, b) => b.length - a.length);
    return pieData;
}

export default function EstimationResult(props: EstimationResultProp): ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const user = useUser();
    const pieData = calcPieData(props.playerEstimates);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const pieColors = theme.chart.pieColors.map(c => c!.toString());

    let i = 0;
    //<Grid item xs={12}><Typography align="center" variant="h5">Estimation Result</Typography></Grid>

    function renderWootric() {
        //TODO pri2: this is so ugly but I simply don't know what to do.
        const userStorage = new UserStorage();
        const originalUserId = userStorage.GetOriginalUserId();
        const userIdentifier = originalUserId != null ? originalUserId : user.user ? user.user.name : "unknown";
        const originalCreatedAt = userStorage.GetOriginalUserCreatedAt();
        const userCreatedAt = originalCreatedAt != null ? originalCreatedAt : new Date();
        const userName = user.user ? user.user.name : "unknown";
        const isModerator = user.user ? user.user.isModerator : false;

        return (
            <Wootric userIdentifier={userIdentifier} userCreatedAt={userCreatedAt} userName={userName}  isModetator={isModerator} />
        );
    }
            
    return (
        <Grid container className={classes.container} spacing={2} justifyContent="space-between" alignItems="center">
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
                <PieChart
                    style={{ fontFamily: "Roboto", fontSize: "10px" }} labelStyle={{ fill: '#fff' }}
                    label={({ dataEntry }) => dataEntry.title} startAngle={-90} animate={true} className={classes.pieChart}
                    labelPosition={50+(pieData.length)}
                    data={pieData.map(entry => ({ title: entry[0].storyPoint, value: entry.length, color: pieColors[Math.min(pieColors.length - 1, i++)] }))}
                />
            </Grid>
            <Grid item xs={3}></Grid>
            {props.roundCount >= 3 && renderWootric()}
        </Grid>
    );
}